<template>
  <div>
    <div v-if="methodData.active">
      <v-row class="mb-0">
        <v-col cols="3" class="pr-0">
          <span class="font-weight-medium">Delivery Method: </span>
        </v-col>
        <v-col cols="8" class="px-1">
          <span v-if="isMethodsLoading">
            <v-skeleton-loader type="text" class="delivery-loader pt-1" />
          </span>
          <hb-select
            v-else
            v-model="methodData.method.id"
            attach
            :clearable="false"
            label="Delivery Method"
            :items="items"
            v-validate="'required'"
            data-vv-as="Delivery Methods"
            data-vv-name="delivery_method"
            :error-messages="errors.collect(`delivery_method`)"
            :error="delivery_method_error"
            :no-data-text="getDeliveryMethodsEmptyText"
            item-text="name"
            item-value="id"
            :readonly="viewOnly"
            @input="clearMethodData()"
          >
          </hb-select>
        </v-col>
        <v-col cols="1" class="pa-0">
          <hb-btn
            v-if="!isMethodsLoading"
            icon
            tooltip="Clear"
            :disabled="viewOnly"
            mdi-code="mdi-close"
            class="close-button d-flex"
            @click="clearDeliveryMethod()"
          />
        </v-col>
      </v-row>
      <div v-if="showSubjectLine">
        <v-row class="mb-0 ">
          <span class="ml-2 pa-2">Search for an existing template from the  template manager library</span>
          <v-col cols="3" class="d-flex align-center">
            <span class="font-weight-medium">Template: </span>
          </v-col>
          <v-col cols="9" class="px-1">
            <HbAutocomplete v-model="parsedMessage1" :items="templateNames" v-validate="'required|max:45'" item-text='name'
            item-value='template_id' data-vv-scope="default" data-vv-name="autocomplete-box" data-vv-as="Autocomplete Box"
            :error="errors.has('default.autocomplete-box')" placeholder="Select Template" class="mt-2 pl-1"
            @change="appendtemplate($event)" @click:clear="clearTemplate" />
          </v-col>
        </v-row>
        <v-row class="mb-0">
          <v-col cols="12" class="py-1">
            <v-text-field
              class="mt-0"
              label="Subject Line"
              v-model="methodData.subject"
              v-validate="'required'"
              data-vv-as="Email subject"
              :data-vv-name="`email_subject`"
              :single-line="false"
              :error-messages="errors.collect(`email_subject`)"
              hide-details
              :readonly="viewOnly"
            />
          </v-col>
        </v-row>
        <v-row class="mb-0">
          <v-col cols="12" class="py-1">
            <span class="email-head-text hb-text-light">Email Content</span>
            <rich-text-editor
              class="email-rich-editor"
              v-model="parsedMessage"
              prose-height="220px"
              :readonly="viewOnly"
            />
            <!-- <div v-else style="max-height: 200px; overflow: auto;">
                                                <div v-html="parsedMessage"></div>
                                                </div> -->
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import RichTextEditor from "../../assets/RichTextEditor.vue";
import api from "../../../assets/api.js";

export default {
  name: "DeliveryMethod",
  mixins: [notificationMixin],
  components: { RichTextEditor },
  props: {
    method: {
      type: Object,
      default: () => ({}),
    },
    isMethodsLoading: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    property_id:{
      type: String
    }
  },
  data() {
    return {
      templateName:'',
      templateNames:[],
      delivery_method_error: false,
      methodData: cloneDeep(this.method),
      initialMethod: cloneDeep(this.method),
    };
  },
  watch: {
    methodData: {
      immediate: true,
      handler(data) {
        if (data?.method?.id && this.items.length) {
          let deliveryData = this.items.find(
            (method) => method.id === data.method.id
          );
          this.methodData.method["key"] = deliveryData.gds_key ?? "";
        }
        this.$emit("saveMethod", data);
      },
      deep: true,
    },
  },
  async mounted() { await this.getBaseTemplateData()},
  computed: {
    parsedMessage1:{
      get() {
        try {
          const content = JSON.parse(this.methodData.message).template_id;
          return content
        }
        catch {
          return this.methodData.message.template_id;
        }
      },
      set(newValue){
      }
    },
    parsedMessage: {
      get() {
        try {
          const content = JSON.parse(this.methodData.message).content;
          return content
        }
        catch {
          return this.methodData.message;
        }
      },
      set(newValue) {
        JSON.parse(this.methodData.message).content = newValue;
      }
    },
    getDeliveryMethodsEmptyText() {
      return !this.items.length ? "No Delivery methods for this property" : "";
    },
    isDuplicate() {
      return this.delivery_method_error;
    },
    showSubjectLine() {
      let show = ["registered_email", "standard_email"];
      let deliveryMethodDetails = null;
      if (this.methodData?.method?.id) {
        deliveryMethodDetails = this.items.find(
          (item) => item.id === this.methodData?.method?.id
        );
      }
      return deliveryMethodDetails && deliveryMethodDetails?.gds_key
        ? show.includes(deliveryMethodDetails.gds_key)
        : false;
    },
  },
  methods: {
    clearTemplate() {
      this.methodData.subject = ''
      this.methodData.message = ''
    },
    async getBaseTemplateData() {
      const templates = await this.getAllTemplatesWithId(this.property_id); // only one property will select at a time
      const templateFilterd =templates.filter(template => template.base_template_id === null)
      this.templateNames = templateFilterd.filter(template => template.type === 'email').map(template => ({ name: template.name, template_id: template.template_id }));
    },
    async getAllTemplatesWithId(properties) {
      return await this.getTemplatesById(properties);
    },
    async getTemplatesById(id) {
      try {
        const response = await api.get(this, api.TEMPLATE_MANAGER + `templates` + `?property_id=${id}&get_only_active_templates=true`);
        return response.templates;
      } catch (error) {
        console.error(`Error fetching templates for property ID ${id}:`, error);
        throw error; // Rethrow the error to propagate it up
      }
    },
    async appendtemplate(id) {
     if(id){
      try {
        await api.get(this, api.TEMPLATE_MANAGER + `template/${id}`).then((res) => {
          if (res.template.body && res.template.subject) {
            const newContent = {
          template_id: id,
          content: res.template.body,
        };

        // Set the new JSON content to methodData.message
        this.methodData.message = JSON.stringify(newContent);
            this.methodData.subject = res.template.subject
          }
          else {
            this.showMessageNotification({ type: 'error', description: "Can't Attach SMS Template to Email Field" });
          }
        })
      } catch (error) {
        throw error;
      }
     }
    },
    clearDeliveryMethod() {
      this.$emit("clearMethod");
    },
    validateFields() {
      this.$validator.validateAll();
    },
    resetDeliveryMethod() {
      this.$nextTick(() => {
        this.methodData = cloneDeep(this.initialMethod);
      });
    },
    saveDeliveryMethod() {
      this.initialMethod = cloneDeep(this.methodData);
    },
    repeatedEntry() {
      this.delivery_method_error = false;
    },
    setDuplicateError(value = false) {
      this.delivery_method_error = value;
    },
    clearMethodData() {
      if (!this.showSubjectLine) {
        this.methodData.subject = "";
        this.methodData.message = "";
      } else {
        this.methodData.message = "[Tenant.FirstName] [Tenant.LastName]";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.delivery-loader {
  width: 110%;
}
.email-rich-editor {
  padding-left: 0px !important;
  padding-top: 12px !important;
}
.email-head-text {
  font-size: 11px;
  line-height: 16px;
}
.close-button {
  padding-top: 6px;
  margin-left: -4px;
}
</style>
