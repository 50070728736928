<template>
  <div v-if="!loading">
    <template>
      <hb-form
        label="Promotion & Discount Stacking"
        description="Enable the stacking of discounts and promotions by a single tenant."
        full
      >
        <hb-switch
          v-model="form.enable_stacking"
          class="mt-2"
          label="Allow Promotions and Discounts to apply at the same time."
          :readonly="viewOnly"
        />
      </hb-form>
      <hb-form
        label="Grouping Profile"
        required
        description="Select the grouping profile you would like to use to apply promotion plans to."
      >
        <hb-select
          v-model="form.space_group_profile"
          data-vv-name="space_group"
          data-vv-as="space grouping profile"
          :error="emptySpaceProfile"
          placeholder="Select Grouping Profile"
          :items="spaceGroups"
          item-text="name"
          :item-value="
            (value) => {
              return { id: value.id, name: value.name };
            }
          "
          :clearable="false"
          class="pa-0 ma-0 mt-3 pb-1"
          :readonly="viewOnly"
        />
      </hb-form>
      <RoundingForm v-model="form.round_to" :read-only="viewOnly" />
    </template>
    <hb-bottom-action-bar v-if="!viewOnly" @close="cancel" :topBorder="false">
      <template v-slot:right-actions>
        <hb-btn
          :disabled="!promoValuesChanged"
          :loading="promoApiLoading"
          @click="confirmData"
          >Save</hb-btn
        >
      </template>
    </hb-bottom-action-bar>
  </div>
</template>

<script type="text/babel">
import { cloneDeep, isEqual } from "lodash";

import api from "../../../assets/api.js";

import RoundingForm from "../../assets/RoundingForm.vue";

export default {
  name: "promotion-management-settings",
  mixins: [],
  props: {
    propertyId: {
      type: String,
      default: "",
    },
    spaceGroups: {
      type: Array,
      default: () => [],
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptySpaceProfile: false,
      form: {
        active: false,
        space_group_profile: {
          id: null,
          name: null,
        },
        round_to: null,
        enable_stacking: false,
      },
      initialData: {},
      promoApiLoading: false,
      loading: true
    };
  },
  components: {
    RoundingForm,
  },
  async created() {
    await this.setInitialData();
  },
  computed: {
    /**
     * Checks if the form object is editted.
     *
     * @returns {Boolean} - true if form object is editted, else false
     */
    promoValuesChanged() {
      const dirty = !isEqual(this.initialData, this.form);
      if (!dirty) this.$validator.errors.clear();
      return dirty && this.form.active;
    },
  },
  watch: {
    /**
     * Watches property to reset validation and fetch initial data.
     */
    propertyId: {
      async handler(val) {
        this.$validator.reset();
        if (val) {
          await this.fetchData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    showNotification(data) {
      this.$emit("setSnackBar", {
        type: data.type,
        message: data.message,
        errors: data.errors,
      });
    },

    async enablePromoManagement(status) {
      this.form.active = !!status;
      if (!status) this.updatePropertySettings();
    },

    async confirmData() {
      this.$validator.errors.clear();
      if (!this.form.space_group_profile?.id) {
        this.$validator.errors.add({
          field: "space_group",
          msg: "Grouping Profile field is required.",
        });
        this.emptySpaceProfile = true;
      } else this.emptySpaceProfile = false;

      this.$validator.validateAll().then((success) => {
        if (success && !this.emptySpaceProfile) {
          this.submitData();
        }
      });
    },
    /**
     * Sets an initial state to compare and watch for changes
     * @param {String} type - can be property, corporate.
     */
    setInitialData() {
      this.initialData = cloneDeep(this.form);
      this.$emit("setEnableStatus", {
        status: !!this.form.active,
        type: "promo",
      });
    },

    /**
     * To reset to initial state
     *
     * Removes the changes done to the form object
     */
    cancel() {
      this.$nextTick(() => {
        this.form = cloneDeep(this.initialData);
        this.$validator.reset();
        this.$emit("setEnableStatus", {
          status: !!this.form.active,
          type: "promo",
        });
      });
    },

    /**
     * Fetch initial data.
     */
    async fetchData() {
      this.loading = true;
      if (this.propertyId) {
        await this.fetchPropertySettings();
      }
      this.loading = false;
    },

    /**
     * Fetch Promo management configurations for property.
     */
    async fetchPropertySettings() {
      try {
        let data = await api.get(
          this,
          api.getPropertyPromoManagementUrl(this.propertyId)
        );
        this.form = {
          ...this.form,
          ...data,
        };
      } catch (err) {
        this.$emit("setSnackBar", {
          type: "error",
          message: "Property configuration fetch error.",
        });
        console.error("Property configuration fetch error", err);
      }
      this.$emit("stopLoading");
      this.setInitialData();
    },

    /**
     * Submit updated setting after validation checks.
     */
    async submitData() {
      if (this.propertyId) {
        await this.updatePropertySettings();
      }
      this.dialog = false;
    },

    /**
     * Submits Promo management configurations for property.
     */
    async updatePropertySettings() {
      this.promoApiLoading = true;

      let data = {
        active: this.form.active ?? false,
        enable_stacking: this.form.enable_stacking ?? false,
        round_to: this.form.round_to ?? null,
        space_group_profile: {
          id: this.form?.space_group_profile.id ?? "",
        },
      };

      let statusChanged =  this.initialData?.active !== data.active;

      try {
        await api
          .put(this, api.getPropertyPromoManagementUrl(this.propertyId), data)
          .then(() => {
            this.$emit("setSnackBar", {
              type: "success",
              message:`You have successfully 
                  ${statusChanged ? (data.active ? 'enabled': 'disabled'): 'updated' } 
                  the Promotions Management Settings.`,
            });
            this.setInitialData();
          })
          .catch(() => {
            this.$emit("setEnableStatus", {
              status: !!data.active,
              type: "promo",
            });
            this.$emit("setSnackBar", {
              type: "error",
              message:`${statusChanged ? (data.active ?'Enabling': 'Disabling'): 'Updating'} 
                      Property Promotions Management settings  failed.`,
            });
          });
      } catch (err) {
        console.error("Failed to update", err);
      }
      this.promoApiLoading = false;
    },
  },
};
</script>

<style lang="scss">
.hb-text-light.rent-settings-box-field-description {
  font-size: 11px;
  line-height: 13px;
}
</style>
